import "firebase/performance";
import "firebase/firestore";
import "firebase/analytics";
import firebase from "firebase/app";

const config = {
  apiKey: "AIzaSyB61eLdg17GhZYCHcfrMD_gs6OKtgbGzVs",
  authDomain: "iquest-consultation-925b3.firebaseapp.com",
  databaseURL: "https://iquest-consultation-925b3.firebaseio.com",
  projectId: "iquest-consultation-925b3",
  storageBucket: "iquest-consultation-925b3.appspot.com",
  messagingSenderId: "189154302546",
  appId: "1:189154302546:web:65ce18ea2ceee6729a1d8b",
};

firebase.initializeApp(config);

export default firebase;
