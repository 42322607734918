import React, { useCallback, useEffect, useState } from "react"
import LeftArrow from "../assets/images/left-arrow.png"
import RightArrow from "../assets/images/right-arrow.png"
import Consultation from "../models/Consultation"
import emailjs from "emailjs-com"
import firebase from "../config/firebase"
import LogoWhite from "../assets/images/Logo-white.png"
import Cross from "../assets/images/cross.png"
import Fine from "../assets/images/confirm.png"

const db = firebase.firestore()
const LOCALE = "cs-CZ"
const IQUEST_EMAIL = "vystrcilova@iquest.cz"

export default function ShowConsultations() {
  const [consultations, setConsultations] = useState([])
  const [consultation, setConsultation] = useState(null)
  const [customerName, setCustomerName] = useState("")
  const [monthSkipped, setMonthSkipped] = useState(false)
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [note, setNote] = useState("")
  const [modalSuccess, setModalSuccess] = useState(false)
  const [month, setMonth] = useState(new Date().getMonth())
  const [year, setYear] = useState(new Date().getFullYear())
  const [errorMessage, setErrorMessage] = useState("")
  const [nameValidation, setNameValidation] = useState(false)
  const [emailValidation, setEmailValidation] = useState(false)
  const [phoneValidation, setPhoneValidation] = useState(false)
  const [verificationMessage, setVerificationMessage] = useState("")

  useEffect(() => {
    const firstDay = new Date(year, month, 1)
    const lastDay = new Date(year, month + 1, 0)
    lastDay.setHours(23)
    lastDay.setMinutes(59)
    lastDay.setSeconds(59)
    const getConsultation = async () => {
      await db
        .collection("consultations")
        .where("date", "<=", lastDay)
        .where("date", ">=", firstDay)
        .orderBy("date", "asc")
        .onSnapshot(({ docs }) => {
          setConsultations(prevConsultations => {
            const consultationData = docs.map(item =>
              Consultation.fromDocSnapshot(item)
            )
            if (
              JSON.stringify(prevConsultations.map(item => item.id)) !==
              JSON.stringify(consultationData.map(item => item.id))
            ) {
              return consultationData
            } else {
              return prevConsultations
            }
          })
        })
    }

    getConsultation()
  }, [consultations, year, month])

  const nextMonth = useCallback(() => {
    if (month === 11) {
      setMonth(0)
      setYear(year + 1)
    } else {
      setMonth(month + 1)
    }
  }, [month, year])

  const previousMonth = () => {
    if (month === 0) {
      setMonth(11)
      setYear(year - 1)
    } else {
      setMonth(month - 1)
    }
  }

  useEffect(() => {
    if (consultations.length && !monthSkipped) {
      const allStatuses = consultations.map(item => item.status)

      if (allStatuses.every(item => item === false)) {
        nextMonth()
        setMonthSkipped(true)
      }
    }
  }, [consultations, monthSkipped, nextMonth])

  const rows = consultations
    ? consultations.map(item => {
        const { date, timeFrom, timeTo, status, id } = item

        return {
          date: date.toDate(),
          timeFrom,
          timeTo,
          status,
          id,
          consultation: item,
        }
      })
    : []

  const onSubmit = async e => {
    e.preventDefault()

    const myDate = new Date(consultation.date.toDate()).toLocaleDateString(
      "cs-CZ"
    )
    const consultationTime = `${consultation.timeFrom} - ${consultation.timeTo}`

    const templateCustomer = {
      consultationDate: myDate,
      consultationTime: consultationTime,
      customerEmail: email,
    }

    const templateCompany = {
      consultationDate: myDate,
      consultationTime: consultationTime,
      companyEmail: IQUEST_EMAIL,
      customerEmail: email,
      customerName: customerName,
      customerPhone: phone,
      note: note,
    }

    if (nameValidation && emailValidation && phoneValidation) {
      try {
        await db
          .collection(`customers`)
          .add({
            customerName,
            email,
            phone,
            note,
            consultationRef: consultation.ref,
            createdAt: firebase.firestore.FieldValue.serverTimestamp(),
            updatedAt: null,
            deletedAt: null,
          })
          .then(() => {
            emailjs
              .send(
                "aplikace_gmail",
                "template_4n8tpmd",
                templateCustomer,
                "user_8mwQcPSKuyH8bcWUYF9Yt"
              )
              .then(
                () => {},
                error => {
                  console.log(error.text)
                }
              )
            emailjs
              .send(
                "aplikace_gmail",
                "template_tz4sd8i",
                templateCompany,
                "user_8mwQcPSKuyH8bcWUYF9Yt"
              )
              .then(
                () => {},
                error => {
                  console.log(error.text)
                }
              )
          })
        setConsultation(null)
        // set temporary status, before cloud function finish
        setConsultations(
          consultations.map(item => {
            if (item.id === consultation.id) {
              item.status = false
            }
            return item
          })
        )
        setCustomerName("")
        setPhone("")
        setEmail("")
        setNote("")
        setModalSuccess(true)
      } catch (error) {
        console.log("error", error)
        setErrorMessage("Došlo k chybě při zápisu")
      }
    } else {
      setVerificationMessage("Prosím vyplňte správně všechna povinná pole")
      const timer = setTimeout(() => {
        setVerificationMessage("")
      }, 3000)
      return () => clearTimeout(timer)
    }
  }

  const showModalSucces = () => {
    return (
      <div className="reservation-form">
        <div className="reservation-form-box">
          <div className="flex-1 reservation-form-inputs p-r-1 p-l-1">
            <div className="reservation-form-title text-center">
              Rezervace termínu proběhla úspěšně.
            </div>
            <div className="reservation-form-text text-center">
              Všechny potřebné informace najdete <br /> ve Vaší e-mailové
              schránce.
            </div>
            <div className="reservation-form-text">
              Těšíme se na váš projekt.
            </div>
          </div>
          <div className="flex-1 reservation-form-background">
            <img
              alt="escape"
              src={Cross}
              className="reservation-form-box-exit"
              onClick={() => setModalSuccess(false)}
            />
            <img
              alt="iQuest-Logo"
              src={LogoWhite}
              className="reservation-form-background-logo"
            />
          </div>
        </div>
      </div>
    )
  }

  const handleNameChange = name => {
    setCustomerName(name)
    if (!name) {
      setNameValidation(false)
    }

    if (name) {
      if (!name.match(/^[a-zA-Z]{2,}/)) {
        setNameValidation(false)
      } else {
        setNameValidation(true)
      }
    }
  }

  const handleEmailChange = mail => {
    setEmail(mail)
    if (!mail) {
      setEmailValidation(false)
    }

    if (mail) {
      let lastAtPos = mail.lastIndexOf("@")
      let lastDotPos = mail.lastIndexOf(".")
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          mail.indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          mail.length - lastDotPos > 2
        )
      ) {
        setEmailValidation(false)
      } else {
        setEmailValidation(true)
      }
    }
  }

  const handlePhoneChange = phone => {
    setPhone(phone)

    if (!phone) {
      setPhoneValidation(false)
    }

    if (phone) {
      if (phone.match(/^[(+)?, 0-9]{9,14}$/)) {
        setPhoneValidation(true)
      } else {
        setPhoneValidation(false)
      }
    }
  }

  const showModalReservation = () => {
    return (
      <div className="reservation-form">
        <form className="reservation-form-box" onSubmit={e => onSubmit(e)}>
          <div className="flex-1 reservation-form-inputs">
            <p className="reservation-form-title">Rezervovat termín</p>
            <p className="reservation-form-subtitle">
              Po rezervaci termínu vám zašleme odkaz pro online hovor.
            </p>
            <div className="flex align-center justify-center  width-80 relative">
              <input
                id="name"
                placeholder="Jméno a příjmení (firma)"
                className="reservation-form-input"
                type="text"
                required
                onChange={event => {
                  handleNameChange(event.target.value)
                }}
              />
              <label className="reservation-form-labels" htmlFor="name">
                Jméno a příjmení (firma)
              </label>
              {nameValidation && (
                <img
                  alt="fine"
                  className="absolute reservation-form-icon"
                  src={Fine}
                />
              )}
            </div>
            <div className="flex align-center justify-center  width-80 relative">
              <input
                id="email"
                required
                placeholder="E-mail"
                className="reservation-form-input"
                type="email"
                onChange={event => {
                  handleEmailChange(event.target.value)
                }}
              />
              <label className="reservation-form-labels" htmlFor="email">
                E-mail
              </label>
              {emailValidation && (
                <img
                  alt="fine"
                  className="absolute reservation-form-icon"
                  src={Fine}
                />
              )}
            </div>
            <div className="flex align-center justify-center  width-80 relative">
              <input
                id="tel"
                required
                placeholder="Tel."
                className="reservation-form-input "
                type="text"
                onChange={event => {
                  handlePhoneChange(event.target.value)
                }}
              />
              <label className="reservation-form-labels" htmlFor="tel">
                Tel.
              </label>
              {phoneValidation && (
                <img
                  alt="fine"
                  className="absolute reservation-form-icon"
                  src={Fine}
                />
              )}
            </div>
            <div className="flex align-center justify-center  width-80 relative">
              <textarea
                id="textarea"
                cols="40"
                rows="5"
                placeholder="Krátký popis vašeho záměru (nepovinné)"
                className="reservation-form-input reservation-form-input-area reservation-form-input-big"
                maxLength="400"
                type="text"
                onChange={event => {
                  setNote(event.target.value)
                }}
              />
              <label className="reservation-form-labels" htmlFor="textarea">
                Krátký popis vašeho záměru (nepovinné)
              </label>
            </div>
            <p className="reservation-form-checkbox-message text-center m-none">
              {verificationMessage}
            </p>

            <div className="flex align-center justify-space-evenly width-100 p-b-3">
              <button
                className="bubble bubble-btn bubble-btn-global pointer reservation-form-btn"
                type="submit"
              >
                Rezervovat termín
              </button>
              <div
                className="reservation-form-leave pointer"
                onClick={() => {
                  setConsultation(null)
                  setEmail("")
                  setCustomerName("")
                  setPhone("")
                  setNote("")
                  handleNameChange()
                  handleEmailChange()
                  handlePhoneChange()
                }}
              >
                Ne, děkuji
              </div>
            </div>
          </div>
          <div className="flex-1 reservation-form-background">
            <img
              alt="escape"
              src={Cross}
              className="reservation-form-box-exit"
              onClick={() => {
                setConsultation(null)
                setEmail("")
                setCustomerName("")
                setPhone("")
                setNote("")
                handleNameChange()
                handleEmailChange()
                handlePhoneChange()
              }}
            />

            <p className="reservation-form-background-text">
              Po rezervaci termínu vám zašleme odkaz pro online hovor.
            </p>
            <img
              alt="iQuest-Logo"
              src={LogoWhite}
              className="reservation-form-background-logo"
            />
          </div>
        </form>
      </div>
    )
  }

  const consultationsByMonthTable = () => {
    const currentMonthConsultation = (
      rows.filter(
        row => row.date.getMonth() === month && row.date.getFullYear() === year
      ) || []
    ).sort((consA, consB) => {
      const fromParts = consA.timeFrom.split(":")
      const fromDate = consA.date
      fromDate.setHours(fromParts[0])
      fromDate.setMinutes(fromParts[1])
      const toParts = consB.timeTo.split(":")
      const toDate = consB.date
      toDate.setHours(toParts[0])
      toDate.setMinutes(toParts[1])
      return fromDate.getTime() - toDate.getTime()
    })

    return (
      <>
        <table className="reservation-calendar-table font-table">
          <tbody>
            {currentMonthConsultation.length ? (
              currentMonthConsultation.map(row => (
                <tr
                  className="reservation-calendar-tr bubble-table"
                  key={row.id}
                >
                  <td className="reservation-calendar-td">
                    <div className="reservation-calendar-td-small">
                      {row.date.toLocaleDateString(LOCALE)}
                    </div>
                  </td>
                  <td className="reservation-calendar-td ">
                    <div className="reservation-calendar-td-small">
                      {row.timeFrom}
                      {" - "}
                      {row.timeTo}
                    </div>
                  </td>
                  <td className="reservation-calendar-td">
                    {row.status ? (
                      <button
                        className="bubble-table bubble-btn bubble-table-btn pointer"
                        onClick={() => setConsultation(row.consultation)}
                      >
                        <p className="bubble-table-btn-text">
                          Rezervovat termín
                        </p>{" "}
                        <p className="bubble-table-btn-text-small">
                          Rezervovat
                        </p>
                      </button>
                    ) : (
                      <div className="flex align-center justify-center reservation-calendar-full">
                        Termín obsazen
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr className="reservation-calendar-tr empty">
                <td>Pro tento měsíc nebyly vypsány termíny</td>
              </tr>
            )}
          </tbody>
        </table>
      </>
    )
  }

  const monthName = idx => {
    const objDate = new Date()
    objDate.setDate(1)
    objDate.setMonth(idx)

    const month = objDate.toLocaleString(LOCALE, { month: "long" })

    return month
  }

  const monthString = monthName(month)

  typeof document !== "undefined" &&
    document.body.classList.toggle("modal-open", consultation)

  return (
    <div>
      {errorMessage}
      <div className="reservation-calendar">
        <div className="flex align-center justify-center">
          <p className="reservation-calendar-title">Volné termíny konzultací</p>
        </div>
        <div className="reservation-calendar-month m-b-1">
          <img
            alt="left-arrow"
            className="pointer reservation-calendar-arrows"
            src={LeftArrow}
            onClick={() => previousMonth()}
          />
          <div className="m-l-3 m-r-3">
            {monthString.charAt(0).toUpperCase() + monthString.slice(1)} {year}
          </div>
          <img
            alt="right-arrow"
            className="pointer reservation-calendar-arrows"
            src={RightArrow}
            onClick={() => nextMonth()}
          />
        </div>
        {consultationsByMonthTable()}
      </div>

      {consultation && showModalReservation()}
      {modalSuccess && showModalSucces()}
    </div>
  )
}
