import { IModel } from '../intefaces';
import {
  SnapshotMetadata,
  DocumentReference,
  DocumentSnapshot,
} from '../types/Firebase';

export default class Model implements IModel {
  id: string;
  exists: Boolean;
  metadata: SnapshotMetadata;
  ref: DocumentReference;

  constructor({id, exists, metadata, ref}: IModel) {
    this.id = id;
    this.exists = exists;
    this.metadata = metadata;
    this.ref = ref;
  }

  static fromDocSnapshot(docSnapshot: DocumentSnapshot) {
    const {id, exists, metadata, ref} = docSnapshot;
    return new Model({
      ...docSnapshot.data(),
      id,
      exists,
      metadata,
      ref,
    });
  }
}
