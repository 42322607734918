import {Model} from '.';
import IConsultation from '../intefaces/IConsultation';
import {IDateModel, IModel} from '../intefaces';
import {DocumentSnapshot, Timestamp} from '../types/Firebase';

export default class Consultation
  extends Model
  implements IConsultation, IDateModel {
  advisor: string;
  customer: string;
  customerEmail: string;
  customerPhone: number;
  customerNote: string;
  GDPR: boolean;
  date: Timestamp;
  status: boolean;
  timeFrom: string;
  timeTo: string;
  createdAt: Timestamp;
  deletedAt: Timestamp | null;
  updatedAt: Timestamp | null;

  constructor(data: IConsultation & IModel & IDateModel) {
    super(data);
    this.advisor = data.advisor;
    this.customer = data.customer;
    this.customerEmail = data.customerEmail;
    this.customerPhone = data.customerPhone;
    this.customerNote = data.customerNote;
    this.GDPR = data.GDPR;
    this.date = data.date;
    this.status = data.status;
    this.timeFrom = data.timeFrom;
    this.timeTo = data.timeTo;
    this.createdAt = data.createdAt;
    this.deletedAt = data.deletedAt;
    this.updatedAt = data.updatedAt;
  }

  static fromDocSnapshot(docSnapshot: DocumentSnapshot) {
    const {id, exists, metadata, ref} = docSnapshot;
    const {advisor,  customer, customerEmail, customerPhone, customerNote, GDPR, date, status, timeFrom, timeTo, createdAt, deletedAt, updatedAt} =
      docSnapshot.data() || {};

    return new Consultation({
      id,
      exists,
      metadata,
      ref,
      advisor,
      customer,
      customerEmail,
      customerPhone,
      customerNote,
      GDPR,
      date,
      status,
      timeFrom,
      timeTo,
      createdAt,
      deletedAt,
      updatedAt,
    });
  }
}
